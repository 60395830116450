import { useEffect, useRef } from 'react';

export default () => {
	const scroller = useRef();

	const handleScroll = () => {
		const main = document.querySelector('main');

		const element = scroller.current;
		if (!element) return;

		element.style.opacity = 1 - main.scrollTop / (window.innerHeight / 2);
	};

	useEffect(() => {
		const main = document.querySelector('main');
		main.addEventListener('scroll', handleScroll, true);

		return () => {
			main.removeEventListener('scroll', handleScroll, true);
		};
	}, []);

	return (
		<div
			ref={scroller}
			className='sm:flex text-textContent hidden fixed w-full bottom-0 left-0 px-5 py-5 h-20 footer-gradient backdrop-blur-md flex-row justify-center place-items-end'>
			scroll for more
		</div>
	);
};
